define('Magento_Customer/js/customer-data',['jquery', 'underscore', 'ko', 'Magento_Customer/js/section-config', 'mage/url', 'mage/storage', 'jquery/jquery-storageapi'], function ($, _, ko, sectionConfig, url) {
  'use strict';

  var options = {},
    storage,
    storageInvalidation,
    invalidateCacheBySessionTimeOut,
    invalidateCacheByCloseCookieSession,
    dataProvider,
    buffer,
    customerData,
    deferred = $.Deferred();
  url.setBaseUrl(window.BASE_URL);
  options.sectionLoadUrl = url.build('customer/section/load');
  invalidateCacheBySessionTimeOut = function (invalidateOptions) {
    var date;
    if (new Date($.localStorage.get('mage-cache-timeout')) < new Date()) {
      storage.removeAll();
    }
    date = new Date(Date.now() + parseInt(invalidateOptions.cookieLifeTime, 10) * 1000);
    $.localStorage.set('mage-cache-timeout', date);
  };
  invalidateCacheByCloseCookieSession = function () {
    if (!$.cookieStorage.isSet('mage-cache-sessid')) {
      storage.removeAll();
    }
    $.cookieStorage.set('mage-cache-sessid', true);
  };
  dataProvider = {
    getFromStorage: function (sectionNames) {
      var result = {};
      _.each(sectionNames, function (sectionName) {
        result[sectionName] = storage.get(sectionName);
      });
      return result;
    },
    getFromServer: function (sectionNames, forceNewSectionTimestamp) {
      var parameters;
      sectionNames = sectionConfig.filterClientSideSections(sectionNames);
      parameters = _.isArray(sectionNames) && sectionNames.indexOf('*') < 0 ? {
        sections: sectionNames.join(',')
      } : [];
      parameters['force_new_section_timestamp'] = forceNewSectionTimestamp;
      return $.getJSON(options.sectionLoadUrl, parameters).fail(function (jqXHR) {
        var _jqXHR$responseText;
        throw new Error((_jqXHR$responseText = jqXHR === null || jqXHR === void 0 ? void 0 : jqXHR.responseText) !== null && _jqXHR$responseText !== void 0 ? _jqXHR$responseText : 'Error loading section');
      });
    }
  };
  ko.extenders.disposableCustomerData = function (target, sectionName) {
    var sectionDataIds,
      newSectionDataIds = {};
    target.subscribe(function () {
      setTimeout(function () {
        storage.remove(sectionName);
        sectionDataIds = $.cookieStorage.get('section_data_ids') || {};
        _.each(sectionDataIds, function (data, name) {
          if (name !== sectionName) {
            newSectionDataIds[name] = data;
          }
        });
        $.cookieStorage.set('section_data_ids', newSectionDataIds);
      }, 3000);
    });
    return target;
  };
  buffer = {
    data: {},
    bind: function (sectionName) {
      this.data[sectionName] = ko.observable({});
    },
    get: function (sectionName) {
      if (!this.data[sectionName]) {
        this.bind(sectionName);
      }
      return this.data[sectionName];
    },
    keys: function () {
      return _.keys(this.data);
    },
    notify: function (sectionName, sectionData) {
      if (!this.data[sectionName]) {
        this.bind(sectionName);
      }
      this.data[sectionName](sectionData);
    },
    update: function (sections) {
      var sectionId = 0,
        sectionDataIds = $.cookieStorage.get('section_data_ids') || {};
      _.each(sections, function (sectionData, sectionName) {
        sectionId = sectionData['data_id'];
        sectionDataIds[sectionName] = sectionId;
        storage.set(sectionName, sectionData);
        storageInvalidation.remove(sectionName);
        buffer.notify(sectionName, sectionData);
      });
      $.cookieStorage.set('section_data_ids', sectionDataIds);
    },
    remove: function (sections) {
      _.each(sections, function (sectionName) {
        storage.remove(sectionName);
        if (!sectionConfig.isClientSideSection(sectionName)) {
          storageInvalidation.set(sectionName, true);
        }
      });
    }
  };
  customerData = {
    init: function () {
      var expiredSectionNames = this.getExpiredSectionNames();
      if (expiredSectionNames.length > 0) {
        _.each(dataProvider.getFromStorage(storage.keys()), function (sectionData, sectionName) {
          buffer.notify(sectionName, sectionData);
        });
        this.reload(expiredSectionNames, false);
      } else {
        _.each(dataProvider.getFromStorage(storage.keys()), function (sectionData, sectionName) {
          buffer.notify(sectionName, sectionData);
        });
        if (!_.isEmpty(storageInvalidation.keys())) {
          this.reload(storageInvalidation.keys(), false);
        }
      }
      if (!_.isEmpty($.cookieStorage.get('section_data_clean'))) {
        this.reload(sectionConfig.getSectionNames(), true);
        $.cookieStorage.set('section_data_clean', '');
      }
    },
    initStorage: function () {
      $.cookieStorage.setConf({
        path: '/',
        expires: new Date(Date.now() + parseInt(options.cookieLifeTime, 10) * 1000)
      });
      storage = $.initNamespaceStorage('mage-cache-storage').localStorage;
      storageInvalidation = $.initNamespaceStorage('mage-cache-storage-section-invalidation').localStorage;
    },
    getExpiredSectionNames: function () {
      var expiredSectionNames = [],
        cookieSectionTimestamps = $.cookieStorage.get('section_data_ids') || {},
        sectionLifetime = options.expirableSectionLifetime * 60,
        currentTimestamp = Math.floor(Date.now() / 1000),
        sectionData;
      _.each(options.expirableSectionNames, function (sectionName) {
        sectionData = storage.get(sectionName);
        if (typeof sectionData === 'object' && sectionData['data_id'] + sectionLifetime <= currentTimestamp) {
          expiredSectionNames.push(sectionName);
        }
      });
      _.each(cookieSectionTimestamps, function (cookieSectionTimestamp, sectionName) {
        sectionData = storage.get(sectionName);
        if (typeof sectionData === 'undefined' || typeof sectionData === 'object' && cookieSectionTimestamp !== sectionData['data_id']) {
          expiredSectionNames.push(sectionName);
        }
      });
      expiredSectionNames = _.intersection(expiredSectionNames, sectionConfig.getSectionNames());
      return _.uniq(expiredSectionNames);
    },
    needReload: function () {
      var expiredSectionNames = this.getExpiredSectionNames();
      return expiredSectionNames.length > 0;
    },
    getExpiredKeys: function () {
      return this.getExpiredSectionNames();
    },
    get: function (sectionName) {
      return buffer.get(sectionName);
    },
    set: function (sectionName, sectionData) {
      var data = {};
      data[sectionName] = sectionData;
      buffer.update(data);
    },
    reload: function (sectionNames, forceNewSectionTimestamp) {
      return dataProvider.getFromServer(sectionNames, forceNewSectionTimestamp).done(function (sections) {
        $(document).trigger('customer-data-reload', [sectionNames]);
        buffer.update(sections);
      });
    },
    invalidate: function (sectionNames) {
      var sectionDataIds, sectionsNamesForInvalidation;
      sectionsNamesForInvalidation = _.contains(sectionNames, '*') ? sectionConfig.getSectionNames() : sectionNames;
      $(document).trigger('customer-data-invalidate', [sectionsNamesForInvalidation]);
      buffer.remove(sectionsNamesForInvalidation);
      sectionDataIds = $.cookieStorage.get('section_data_ids') || {};
      _.each(sectionsNamesForInvalidation, function (sectionName) {
        if (!sectionConfig.isClientSideSection(sectionName)) {
          sectionDataIds[sectionName] += 1000;
        }
      });
      $.cookieStorage.set('section_data_ids', sectionDataIds);
    },
    getInitCustomerData: function () {
      return deferred.promise();
    },
    onAjaxComplete: function (jsonResponse, settings) {
      var sections, redirects;
      if (settings.type.match(/post|put|delete/i)) {
        sections = sectionConfig.getAffectedSections(settings.url);
        if (sections && sections.length) {
          this.invalidate(sections);
          redirects = ['redirect', 'backUrl'];
          if (_.isObject(jsonResponse) && !_.isEmpty(_.pick(jsonResponse, redirects))) {
            return;
          }
          this.reload(sections, true);
        }
      }
    },
    'Magento_Customer/js/customer-data': function (settings) {
      options = settings;
      customerData.initStorage();
      invalidateCacheBySessionTimeOut(settings);
      invalidateCacheByCloseCookieSession();
      customerData.init();
      deferred.resolve();
    }
  };
  $(document).on('ajaxComplete', function (event, xhr, settings) {
    customerData.onAjaxComplete(xhr.responseJSON, settings);
  });
  $(document).on('submit', function (event) {
    var sections;
    if (event.target.method.match(/post|put|delete/i)) {
      sections = sectionConfig.getAffectedSections(event.target.action);
      if (sections) {
        customerData.invalidate(sections);
      }
    }
  });
  return customerData;
});

