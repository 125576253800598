define('TescoMobile_DataLayer/js/algolia-hits-preparator',[], function () {
  'use strict';

  return {
    position: 1,
    clubcardPricesList: {},
    resetPosition: function () {
      var self = this;
      self.position = 1;
    },
    prepareHitUpdatedData: function (hit, helper) {
      var _history$state;
      let self = this,
        data = {},
        sku,
        position,
        price,
        simoContractType = 'SIMO',
        fusionContractType = 'FUSION',
        paygEssentialsType = 'PAYG_ESSENTIALS',
        helperData = !!(helper !== null && helper !== void 0 && helper.lastResults) ? helper.lastResults : helper,
        hitsLastResults = !helperData ? !!hit.__queryId ? hit.__queryId : hit.objectID : (helperData === null || helperData === void 0 ? void 0 : helperData.nbHits) + '|' + (helperData === null || helperData === void 0 ? void 0 : helperData.nbPages) + '|' + ((_history$state = history.state) === null || _history$state === void 0 ? void 0 : _history$state.sortBy);
      if (window.hitsLastResults !== hitsLastResults) {
        window.hitsUpdated = {};
      }
      if (hit.sku.length) {
        var _sku, _hit$tm_contract_type, _hit$tm_contract_type2, _hit$tm_contract_type3, _hit$manufacturer, _hit$name, _hit$promotions, _hit$tm_product_label, _hit$free_gift, _hit$free_gift2, _hit$colors, _hit$colors2;
        sku = Array.isArray(hit.sku) ? hit.sku[0] : hit.sku;
        position = !!hit.__position ? hit.__position.toString() : !!hit.position ? hit.position.toString() : self.position++;
        data.product_group_id = (_sku = sku) === null || _sku === void 0 ? void 0 : _sku.toLowerCase();
        data.product_type = hit !== null && hit !== void 0 && (_hit$tm_contract_type = hit.tm_contract_type) !== null && _hit$tm_contract_type !== void 0 && _hit$tm_contract_type.includes(simoContractType) || hit !== null && hit !== void 0 && (_hit$tm_contract_type2 = hit.tm_contract_type) !== null && _hit$tm_contract_type2 !== void 0 && _hit$tm_contract_type2.includes(fusionContractType) || hit !== null && hit !== void 0 && (_hit$tm_contract_type3 = hit.tm_contract_type) !== null && _hit$tm_contract_type3 !== void 0 && _hit$tm_contract_type3.includes(paygEssentialsType) ? 'simo' : 'handset';
        data.product_brand = !!(hit !== null && hit !== void 0 && hit.manufacturer) ? hit === null || hit === void 0 || (_hit$manufacturer = hit.manufacturer) === null || _hit$manufacturer === void 0 ? void 0 : _hit$manufacturer.toString().toLowerCase() : '';
        data.product_name = !!(hit !== null && hit !== void 0 && hit.name) ? hit === null || hit === void 0 || (_hit$name = hit.name) === null || _hit$name === void 0 ? void 0 : _hit$name.replace(data.product_brand, '').trim().toString().toLowerCase() : '';
        data.product_list_position = position.toString();
        data.product_has_offer = (hit === null || hit === void 0 || (_hit$promotions = hit.promotions) === null || _hit$promotions === void 0 ? void 0 : _hit$promotions.length) > 0 || (_hit$tm_product_label = hit.tm_product_label) !== null && _hit$tm_product_label !== void 0 && _hit$tm_product_label.length ? '1' : '0';
        data.product_gift_name = (hit === null || hit === void 0 || (_hit$free_gift = hit.free_gift) === null || _hit$free_gift === void 0 ? void 0 : _hit$free_gift.label) || '';
        data.product_gift_id = (hit === null || hit === void 0 || (_hit$free_gift2 = hit.free_gift) === null || _hit$free_gift2 === void 0 ? void 0 : _hit$free_gift2.id) || '';
        const outOfStockColors = [];
        hit === null || hit === void 0 || (_hit$colors = hit.colors) === null || _hit$colors === void 0 ? void 0 : _hit$colors.forEach(function (color) {
          if (color.in_stock !== true) {
            outOfStockColors.push(color.label);
          }
        });
        data.product_colour_out_of_stock = outOfStockColors.join('|').toLowerCase();
        let stockStatus;
        if ((hit === null || hit === void 0 || (_hit$colors2 = hit.colors) === null || _hit$colors2 === void 0 ? void 0 : _hit$colors2.length) === outOfStockColors.length) {
          stockStatus = 'no stock';
        } else {
          var _hit$limited_stock;
          let isLimitedStock = !!((_hit$limited_stock = hit.limited_stock) !== null && _hit$limited_stock !== void 0 && _hit$limited_stock.show_message);
          if (isLimitedStock) {
            stockStatus = 'limited stock';
          } else {
            var _hit$price;
            price = !!hit.price && Object.keys(hit.price).length ? (_hit$price = hit.price) === null || _hit$price === void 0 ? void 0 : _hit$price.GBP : null;
            stockStatus = !!price ? 'in stock' : 'no stock';
          }
        }
        data.product_stock_status = stockStatus;
        data.product_has_clubcard_pricing = this.prepareHasClubcardPricing(hit);
        if (!window.hitsUpdated) {
          window.hitsUpdated = {};
        }
        window.hitsUpdated[position] = data;
        window.hitsLastResults = hitsLastResults;
      }
    },
    prepareHasClubcardPricing: function (hit) {
      return hit.clubcard ? '1' : '0';
    }
  };
});

