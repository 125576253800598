define('TescoMobile_Algolia/js/model/autocomplete/transformHit',['jquery', 'TescoMobile_Algolia/js/isNew'], function ($, isNew) {
  return function (hit, price_key, helper) {
    var _algoliaConfig, _hit$tm_product_label, _hit$tm_product_label3, _hit$tm_product_label5;
    const isQueryStringAllowedInLinks = ((_algoliaConfig = algoliaConfig) === null || _algoliaConfig === void 0 || (_algoliaConfig = _algoliaConfig.ccAnalytics) === null || _algoliaConfig === void 0 ? void 0 : _algoliaConfig.isQueryStringAllowedInLinks) || false;
    const isContractType = contractType => {
      var _hit$tm_contract_type;
      return hit === null || hit === void 0 || (_hit$tm_contract_type = hit.tm_contract_type) === null || _hit$tm_contract_type === void 0 ? void 0 : _hit$tm_contract_type.includes(contractType.toUpperCase());
    };
    hit.__is_simo = isContractType('SIMO');
    hit.__is_aus = isContractType('AUSTANDARD');
    hit.__is_aui = isContractType('AUIMMEDIATE');
    hit.__is_bau = isContractType('BAU');
    hit.__is_payg_essentials = isContractType('PAYG_ESSENTIALS');
    hit.__is_paym = hit.__is_aus || hit.__is_aui || hit.__is_bau;
    hit.__is_new = isNew(hit);
    if (typeof hit.manufacturer !== "undefined") {
      hit.__manufacturer = Array.isArray(hit.manufacturer) ? hit.manufacturer[0] : hit.manufacturer;
    }
    hit._highlightResult.name.value = hit.name.indexOf(hit.manufacturer) === 0 ? hit.name.replace(hit.manufacturer, '').trim() : hit.name;
    if (typeof hit.tm_allowance_data_unlimited !== "undefined") {
      hit.__tm_allowance_data_formated = 'Unlimited data';
    } else {
      if (hit.tm_allowance_data) {
        if (hit.tm_allowance_data < 1024) {
          hit.__tm_allowance_data_formated = hit.tm_allowance_data + 'MB';
        } else {
          hit.__tm_allowance_data_formated = Math.round(hit.tm_allowance_data / 1024) + 'GB';
        }
      }
    }
    if (typeof hit.tm_allowance_minutes_unlimited !== "undefined" && typeof hit.tm_allowance_sms_unlimited !== "undefined") {
      hit.__tm_minutes_sms_label = 'Unlimited minutes & texts';
    } else {
      let label;
      if (hit.tm_allowance_minutes) {
        label = hit.tm_allowance_minutes + ' minutes';
      }
      if (hit.tm_allowance_sms) {
        label += ' & ' + hit.tm_allowance_sms + ' texts';
      }
      hit.__tm_minutes_sms_label = label;
    }
    if (typeof hit.tm_allowance_minutes_unlimited !== "undefined" && typeof hit.tm_allowance_sms_unlimited === "undefined") {
      let label = hit.tm_allowance_sms + ' texts';
      hit.__tm_minutes_sms_label = 'Unlimited minutes & ' + label;
    }
    if (typeof hit.tm_allowance_minutes_unlimited === "undefined" && typeof hit.tm_allowance_sms_unlimited !== "undefined") {
      let label = hit.tm_allowance_minutes + ' minutes ';
      hit.__tm_minutes_sms_label = label + '& texts';
    }
    if (typeof hit.tm_network_service !== "undefined") {
      let label = 'Works on';
      if (hit.tm_network_service.indexOf('4G') !== -1 && hit.tm_network_service.indexOf('5G') !== -1) {
        label += ' 4G & 5G';
      } else {
        if (hit.tm_network_service.indexOf('4G') !== -1) {
          label += ' 4G';
        }
        if (hit.tm_network_service.indexOf('5G') !== -1) {
          label += ' 5G';
        }
      }
      hit.__tm_network_service_label = label;
    }
    if (hit.tm_tariff_tenure) {
      if (hit.tm_tariff_tenure[0] > 1) {
        hit.contract_duration = hit.tm_tariff_tenure[0] + ' months contract';
      } else {
        hit.contract_duration = hit.tm_tariff_tenure[0] + ' month contract';
      }
    }
    if (hit.__is_simo || hit.__is_payg_essentials) {
      hit._highlightResult.name.value = hit.__tm_allowance_data_formated;
    }
    if (hit.amtoolkit_canonical) {
      hit.url = hit.amtoolkit_canonical;
    }
    if (typeof window.algolia.earlyUpgrade !== "undefined") {
      if (!hit.__is_simo) {
        hit.__early_upgrade = hit.__is_bau ? window.algolia.earlyUpgrade.bau : window.algolia.earlyUpgrade.aus;
      } else {
        hit.__early_upgrade = window.algolia.earlyUpgrade.simo;
      }
    }
    hit.urlForTmInsights = hit.url;
    if (algoliaConfig.ccAnalytics.enabled && isQueryStringAllowedInLinks === true && algoliaConfig.ccAnalytics.conversionAnalyticsMode !== 'disabled') {
      const insightsDataUrlString = $.param({
        queryID: hit.__autocomplete_queryID,
        objectID: hit.objectID,
        indexName: hit.__autocomplete_indexName
      });
      if (hit.url.indexOf('?') > -1) {
        hit.urlForTmInsights += '&' + insightsDataUrlString;
      } else {
        hit.urlForTmInsights += '?' + insightsDataUrlString;
      }
    }
    if (hit.__is_paym && hit !== null && hit !== void 0 && (_hit$tm_product_label = hit.tm_product_label) !== null && _hit$tm_product_label !== void 0 && _hit$tm_product_label.tm_sold_as_paym) {
      var _hit$tm_product_label2;
      hit.tm_product_label = hit === null || hit === void 0 || (_hit$tm_product_label2 = hit.tm_product_label) === null || _hit$tm_product_label2 === void 0 ? void 0 : _hit$tm_product_label2.tm_sold_as_paym;
    } else if (hit.__is_payg && hit !== null && hit !== void 0 && (_hit$tm_product_label3 = hit.tm_product_label) !== null && _hit$tm_product_label3 !== void 0 && _hit$tm_product_label3.tm_sold_as_payg) {
      var _hit$tm_product_label4;
      hit.tm_product_label = hit === null || hit === void 0 || (_hit$tm_product_label4 = hit.tm_product_label) === null || _hit$tm_product_label4 === void 0 ? void 0 : _hit$tm_product_label4.tm_sold_as_payg;
    } else if (hit.__is_simfree && hit !== null && hit !== void 0 && (_hit$tm_product_label5 = hit.tm_product_label) !== null && _hit$tm_product_label5 !== void 0 && _hit$tm_product_label5.tm_sold_as_sim_free) {
      var _hit$tm_product_label6;
      hit.tm_product_label = hit === null || hit === void 0 || (_hit$tm_product_label6 = hit.tm_product_label) === null || _hit$tm_product_label6 === void 0 ? void 0 : _hit$tm_product_label6.tm_sold_as_sim_free;
    } else {
      hit.tm_product_label = null;
    }
    hit.__tm_is_scd = (hit === null || hit === void 0 ? void 0 : hit.tm_is_scd) === "Yes";
    hit.__tm_has_product_label = hit.__tm_is_scd || (hit === null || hit === void 0 ? void 0 : hit.__early_upgrade) || (hit === null || hit === void 0 ? void 0 : hit.tm_product_label);
    return hit;
  };
});

