define('TescoMobile_Algolia/js/model/session-storage',[], function () {
  return {
    setSessionStorage: function (queryId, objectId, indexName, position) {
      let isSearchPage = arguments.length > 4 && arguments[4] !== undefined ? arguments[4] : false;
      sessionStorage.setItem("algoliaInsightInfo", JSON.stringify({
        queryId: queryId,
        objectId: objectId,
        indexName: indexName,
        position: position,
        isSearchPage: isSearchPage
      }));
    },
    getSessionStorage: function () {
      const algoliaInsightInfo = sessionStorage.getItem("algoliaInsightInfo") || null;
      try {
        return JSON.parse(algoliaInsightInfo) || {};
      } catch (e) {
        return {};
      }
    }
  };
});

