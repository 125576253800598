define('TescoMobile_Algolia/js/filters',['jquery', 'Magento_Ui/js/modal/modal'], function ($, modal) {
  'use strict';

  return function () {
    if ($('.filter-holder').length) {
      const options = {
          type: 'slide',
          responsive: true,
          innerScroll: true,
          modalClass: 'filter-modal'
        },
        holder = $('.filter-holder'),
        popup = modal(options, holder);
      $('#refine-toggle').on('click', function () {
        holder.modal('openModal');
        setTimeout(function () {
          $('#refine-toggle').html('+ Filter');
        }, 800);
      });
      $('.filter-close, .show-products').on('click', function () {
        holder.modal('closeModal');
        $('body').removeClass('filter-active');
      });
      mediaCheck({
        media: '(min-width: 1008px)',
        entry: function () {
          holder.insertAfter('.mobile-action-bar');
          holder.modal('closeModal');
        },
        exit: function () {
          holder.prependTo('.filter-modal .modal-content');
        }
      });
      $('.algolia-infos .filter-action').click(function () {
        const body = $('body');
        if (body.hasClass('filter-active')) {
          body.removeClass('filter-active');
        } else {
          body.addClass('filter-active');
        }
      });
      $('.ais-Panel-header').click(function () {
        const parent = $(this).parent();
        parent.find('.ais-Panel-body').toggle();
        parent.hasClass('hidden') ? parent.removeClass('hidden') : parent.addClass('hidden');
      });
      $('.ais-RefinementList').each(function () {
        const list = $(this),
          item = list.find('.ais-RefinementList-showMore');
        item.click(function () {
          $(this).text().indexOf('Show more') >= 0 ? $(this).removeClass('show') : $(this).addClass('show');
          $('.is-widget-container-color ul li').each(function (i) {
            const colorName = $(this).find('.name').text();
            $(this).find('.swatch').css('background-color', swatchData[colorName]);
          });
        });
      });
    }
  };
});

