define('TescoMobile_Algolia/js/model/instantsearch/position-breakout-tiles',['Magento_Customer/js/customer-data'], function (customerData) {
  return {
    positionBreakoutTiles: function () {
      var _algoliaConfig, _algoliaConfig2;
      if (!((_algoliaConfig = algoliaConfig) !== null && _algoliaConfig !== void 0 && (_algoliaConfig = _algoliaConfig.breakoutTile) !== null && _algoliaConfig !== void 0 && _algoliaConfig.containerSelector) || !((_algoliaConfig2 = algoliaConfig) !== null && _algoliaConfig2 !== void 0 && (_algoliaConfig2 = _algoliaConfig2.breakoutTile) !== null && _algoliaConfig2 !== void 0 && _algoliaConfig2.tileSelector)) {
        return;
      }
      const tiles = document.querySelectorAll(algoliaConfig.breakoutTile.tileSelector);
      const destination = document.querySelector(algoliaConfig.breakoutTile.containerSelector);
      if (!destination || !tiles.length) {
        return;
      }
      tiles.forEach(function (tile) {
        this.positionBreakoutTile(tile, destination);
      }.bind(this));
    },
    positionBreakoutTile: function (tile, destination) {
      try {
        var _customerData$get$ite;
        const properties = JSON.parse(tile.getAttribute('data-properties'));
        const position = parseInt(properties.position);
        const customerProductRule = parseInt(properties.single_line_one_product);
        const ccaSingleLineProduct = parseInt(properties.cca_single_line_one_product);
        const targetPosition = position - 1 < 0 ? 0 : position - 1;
        const referenceNode = destination.childNodes[targetPosition];
        const isLoggedIn = !!customerData.get('customer')().firstname;
        const itemsInCart = !isLoggedIn ? (_customerData$get$ite = customerData.get('cart')().items) === null || _customerData$get$ite === void 0 ? void 0 : _customerData$get$ite.length : 0;
        const activeSubscription = isLoggedIn ? customerData.get('customer')().active_subscriptions || 0 : 0;
        const ccaOneSubscription = isLoggedIn && ccaSingleLineProduct ? customerData.get('customer')().cca_active_subscriptions || 0 : 0;
        const totalSubscriptions = activeSubscription + ccaOneSubscription;
        if (customerProductRule === 1) {
          if (itemsInCart === 1 || totalSubscriptions === 1) {
            this.insertBreakoutTile(tile, destination, referenceNode);
          }
        } else if (customerProductRule === 2) {
          if (totalSubscriptions > 1 && totalSubscriptions < 5 || itemsInCart === 1) {
            this.insertBreakoutTile(tile, destination, referenceNode);
          }
        } else {
          this.insertBreakoutTile(tile, destination, referenceNode);
        }
      } catch (e) {
        console.error('Error moving breakout tile:', e);
      }
    },
    insertBreakoutTile: function (tile, destination, referenceNode) {
      if (referenceNode) {
        destination.insertBefore(tile, referenceNode);
      } else {
        destination.appendChild(tile);
      }
    }
  };
});

