define('TescoMobile_Algolia/js/template/autocomplete/products',['jquery', 'mage/translate', 'algoliaCommon'], function ($, $t) {
  return {
    getNoResultHtml(_ref) {
      var _$;
      let html = _ref.html;
      const _algoliaConfig = algoliaConfig,
        autocomplete = _algoliaConfig.autocomplete,
        popularQueries = _algoliaConfig.popularQueries,
        showSuggestionsOnNoResultsPage = _algoliaConfig.showSuggestionsOnNoResultsPage,
        translations = _algoliaConfig.translations,
        resultPageUrl = _algoliaConfig.resultPageUrl;
      const query = (_$ = $(autocomplete.selector)) === null || _$ === void 0 || (_$ = _$.find('input')) === null || _$ === void 0 ? void 0 : _$.val();
      const maxSuggestions = Math.min(3, popularQueries.length);
      const buildSearchUrl = query => {
        const url = new URL(resultPageUrl);
        url.searchParams.set('q', query);
        return url;
      };
      return html`
                <div class="no-results-products">
                    <div class="title">${translations.noProducts} "${query}"</div>
                    ${showSuggestionsOnNoResultsPage && (popularQueries === null || popularQueries === void 0 ? void 0 : popularQueries.length) > 0 ? html`
                        <div class="suggestions">
                            <div>${translations.popularQueries}</div>
                            <div>
                                ${popularQueries.slice(0, maxSuggestions).map((query, i) => html`<a href="${buildSearchUrl(query)}">${query}</a>${i < maxSuggestions - 1 ? ', ' : ''}`)}
                            </div>
                        </div>` : ''}
                    <div class="see-all">
                        ${(popularQueries === null || popularQueries === void 0 ? void 0 : popularQueries.length) > 0 ? html`${translations.or} ` : ''}
                        <a href="${buildSearchUrl('__empty__')}">${translations.seeAll}</a>
                    </div>
                </div>`;
    },
    getHeaderHtml() {
      return "";
    },
    getItemHtml(_ref2) {
      let item = _ref2.item,
        components = _ref2.components,
        html = _ref2.html;
      if (item.__is_simo) {
        return this.getSimoHtml({
          item,
          components,
          html
        });
      }
      return this.getDeviceHtml({
        item,
        components,
        html
      });
    },
    getFooterHtml() {
      return "";
    },
    getSimoHtml(_ref3) {
      var _item$promotions;
      let item = _ref3.item,
        components = _ref3.components,
        html = _ref3.html;
      const _algoliaConfig$autoco = algoliaConfig.autocomplete,
        _algoliaConfig$autoco2 = _algoliaConfig$autoco.simoCategoryLink,
        simoCategoryLink = _algoliaConfig$autoco2 === void 0 ? '#' : _algoliaConfig$autoco2,
        _algoliaConfig$autoco3 = _algoliaConfig$autoco.simoScdCategoryLink,
        simoScdCategoryLink = _algoliaConfig$autoco3 === void 0 ? '#' : _algoliaConfig$autoco3;
      return html`
                ${item.__recommendation ? '<div class="recommendation">Our recommendation</div>' : ''}
                ${item.__bubbleFullPrice ? '<div class="recommendation">Family Pack Everyday price</div>' : ''}
                ${item.__bubbleFullNoPrice ? '<div class="recommendation">Family Pack discount</div>' : ''}
                <div class="product-item simo ${item.__recommendation ? 'recommend' : ''}"
                     itemprop="itemListElement"
                     itemscope
                     itemtype="http://schema.org/ListItem">
                    <meta itemprop="position" content="${item.position}"/>
                    <div class="result-wrapper" itemprop="item" itemscope itemtype="http://schema.org/Product">
                        <meta itemprop="url"
                              content="${item.urlForTmInsights}"/>
                        <span class="result"
                              data-objectId="${item.objectID}"
                              data-position="${item.position}"
                              data-index="${item.__autocomplete_indexName}"
                              data-queryId="${item.__autocomplete_queryID}">
                            <div class="result-content">
                                <div class="result-pre-content">
                                    ${this.getPreContentHtml(item, html)}
                                    <div class="result-promotion-top-line">
                                        ${this.getSuperColleagueDealLabelHtml(item, html)}
                                        <div class="result-promotion-top-line">${this.getEarlyUpgradeLabelHtml(item, html)}</div>
                                    </div>
                                    ${item.__tm_allowance_data_formated ? html`<h3 itemprop="name" class="result-title">
                                            ${item.__tm_allowance_data_formated}</h3>` : ''}
                                    <div class="contract">${item.contract_duration}</div>
                                    <div class="description">${item.short_description}</div>
                                </div>
                                <div class="result-middle-content">
                                    <div class="result-thumbnail">${this.getThumbnailHtml(item, html)}</div>
                                    <div class="result-types">
                                        <div class="type"><span
                                            class="icon icon-comments"></span>${item.__tm_minutes_sms_label}</div>
                                        <div class="type"><span
                                            class="icon icon-coverage-checker"></span>${item.__tm_network_service_label}</div>
                                        <div class="type"><span
                                            class="icon icon-roaming"></span>${$t('No EU roaming fees until 2026')}</div>
                                    </div>
                                </div>
                                <div class="result-price-content">
                                    <div class="ratings">
                                        <div class="result-sub-content">
                                            <div class="price">
                                                ${this.getOfferHtml(item, html)}
                                            </div>
                                            <div class="product-add-form add-to-cart">
                                                <a href="${item.__tm_is_scd ? simoScdCategoryLink : simoCategoryLink}"
                                                   class="action primary secondary">${$t('Shop SIM deals')}</a>
                                            </div>
                                        </div>
                                    </div>
                                    ${this.getProductContainerHtml(item, html)}
                                    ${((_item$promotions = item.promotions) === null || _item$promotions === void 0 ? void 0 : _item$promotions.length) > 0 ? html`
                                        <div class="result-lower-content">
                                            <div class="promo-bottom-holder">
                                                ${this.getPromotionsHtml(item, html)}
                                            </div>
                                        </div>` : ''}
                                </div>
                                <div class="algolia-clearfix"></div>
                            </div>
                        </span>
                    </div>
                </div>
            `;
    },
    getDeviceHtml(_ref4) {
      let item = _ref4.item,
        html = _ref4.html;
      return html`<a class="algoliasearch-autocomplete-hit product-item"
                           href="${item.urlForTmInsights}"
                           data-objectId="${item.objectID}"
                           data-position="${item.position}"
                           data-index="${item.__autocomplete_indexName}"
                           data-queryId="${item.__autocomplete_queryID}">
                <div class="result-pre-content">
                    ${this.getPreContentHtml(item, html)}
                    <div class="result-promotion-top-line">
                        ${this.getSuperColleagueDealLabelHtml(item, html)}
                        <div class="result-promotion-top-line">${this.getEarlyUpgradeLabelHtml(item, html)}</div>
                    </div>
                    <div class="product-title-holder">
                        <div class="product-title-holder__title-wrapper">
                            <div class="product-item-brand">${item.manufacturer}</div>
                            <h3 class="title">${item.name}</h3>
                        </div>
                        <div class="promotions">
                            <div class="promotion__wrapper">
                                ${this.getProductLabelHtml(item, html)}
                            </div>
                            <div class="promotion__wrapper">
                                ${item.tm_product_label ? this.getLabelHtml(html, item.tm_product_label, 'offer') : ''}
                            </div>
                            <div class="promotion__wrapper">
                                ${item.__is_new ? this.getLabelHtml(html, 'New', 'new') : ''}
                            </div>
                        </div>
                    </div>
                </div>
                <div class="result-thumbnail image-loading">
                    ${this.getSpinningLoaderHtml(item, html)}
                    ${this.getThumbnailHtml(item, html)}
                    ${this.getColorSwatchHtml(item, html)}
                </div>
                ${this.getPricingHtml(item, html)}
                <div class="result-lower-content">
                    <div class="ratings">
                        <div class="result-sub-content">${this.getOfferHtml(item, html)}</div>
                    ${item.__is_aus ? html`
                            <div class="contract">${$t('with a phone credit contract')} <b>${$t('0% APR')}</b></div>` : ''}
                    </div>
                    ${this.getProductContainerHtml(item, html)}
                    <div class="promo-bottom-holder">
                        ${this.getPromotionsHtml(item, html)}
                        ${this.getAfterPromoHtml(item, html)}
                        <div class="result-bottom">
                            ${item.limited_stock ? html`
                                <div class="stock">${item.limited_stock.message}</div>` : ''}
                        </div>
                    </div>
                </div>
            </a>`;
    },
    getPromotionsHtml(item, html) {
      return item.search_promotions.length > 0 ? html`
                <div class="product-item-promo">
                    ${item.search_promotions.map(_ref5 => {
        let icon = _ref5.icon;
        return html`<span class="icon ${icon}"></span>`;
      })}
                    ${item.search_promotions.length === 1 ? html`<span class="text">${item.search_promotions.map(_ref6 => {
        let name = _ref6.name;
        return name;
      })}</span>` : html`<span
                            class="text">${$t('%1 offers available').replace('%1', item.promotions.length)}</span>`}
                    <div class="product-item-dialog">
                        <div class="close"><span class="icon icon-close"></span></div>
                        <div class="title">${$t('Offers')}</div>
                        ${item.search_promotions.map(_ref7 => {
        let icon = _ref7.icon,
          name = _ref7.name;
        return html`
                                <div class="promo-item">
                                    <span class="icon ${icon}"></span><span class="text">${name}</span>
                                </div>`;
      })}
                    </div>
                </div>` : '';
    },
    getColorSwatchHtml(item, html) {
      var _item$colors, _item$colors2;
      return (item === null || item === void 0 || (_item$colors = item.colors) === null || _item$colors === void 0 ? void 0 : _item$colors.length) > 0 ? html`
                <div class="product-item-swatches">
                    ${(_item$colors2 = item.colors) === null || _item$colors2 === void 0 ? void 0 : _item$colors2.map(_ref8 => {
        let in_stock = _ref8.in_stock,
          hex_code = _ref8.hex_code;
        return html`
                        <div class="swatch ${in_stock ? '' : 'disabled'}"
                             style="background: ${hex_code}"></div>`;
      })}
                    <div class="icon icon-add ${item.__showMoreColor ? 'show' : ''} "></div>
                </div>` : '';
    },
    getPricingHtml(item, html) {
      if (typeof item['price'] === 'undefined') {
        return '';
      }
      const priceGroup = algoliaConfig.priceGroup || 'default';
      return html`
                <div class="algoliasearch-autocomplete-price">
                <span
                    class="after_special ${item['price'][algoliaConfig.currencyCode][priceGroup + '_original_formated'] != null ? 'promotion' : ''}">
                    ${item['price'][algoliaConfig.currencyCode][priceGroup + '_formated']}
                </span>
                    ${this.getOriginalPriceHtml(item, html, priceGroup)}
                </div>`;
    },
    getOriginalPriceHtml(item, html, priceGroup) {
      if (item['price'][algoliaConfig.currencyCode][priceGroup + '_original_formated'] == null) {
        return '';
      }
      return html`<span
                class="before_special">${item['price'][algoliaConfig.currencyCode][priceGroup + '_original_formated']} </span>`;
    },
    getOfferHtml(item, html) {
      const priceGroup = algoliaConfig.priceGroup || 'default';
      return html`${item.__is_paym ? html`<span class="offers-text">${$t('From')}\u00A0</span>` : ''}
            <div itemprop="offers" itemscope class="price">
                ${item['price'][algoliaConfig.currencyCode][`${priceGroup}_max`] ? html`
                    <meta itemprop="lowPrice"
                          content="${item['price'][algoliaConfig.currencyCode][`${priceGroup}`]}"/>
                    <meta itemprop="highPrice"
                          content="${item['price'][algoliaConfig.currencyCode][`${priceGroup}_max`]}"/>
                ` : html`
                    <meta itemprop="price" content="${item['price'][algoliaConfig.currencyCode][`${priceGroup}`]}"/>`}
                <meta itemprop="priceCurrency" content="${algoliaConfig.currencyCode}"/>
                <div class="price-wrapper">
                    <div>${item['price'][algoliaConfig.currencyCode][`${priceGroup}_formated`]}</div>
                </div>
            </div>
            ${item.__is_paym ? html`<span class="offers-text">\u00A0${$t('a month')}</span>` : ''}`;
    },
    getThumbnailHtml(item, html) {
      return item.image_url ? html`<img itemprop="image" src="${item.image_url}" loading="lazy" alt="${item.name}"/>` : html`<span class="no-image"></span>`;
    },
    getSpinningLoaderHtml(item, html) {
      return html`
                <div class="spinning-loader"></div>`;
    },
    getEarlyUpgradeLabelHtml(item, html) {
      return item.__early_upgrade ? this.getLabelHtml(html, item.__early_upgrade, 'early-upgrade') : '';
    },
    getSuperColleagueDealLabelHtml(item, html) {
      return item.__tm_is_scd ? this.getLabelHtml(html, $t('Super Colleague Deal'), 'super') : '';
    },
    getLabelHtml(html, label) {
      let cssClass = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : '';
      return html`
                <div class="label ${cssClass}">
                    <div class="text">${label}</div>
                </div>`;
    },
    getProductContainerHtml(item, html) {
      return '';
    },
    getPreContentHtml(item, html) {
      return '';
    },
    getProductLabelHtml(item, html) {
      return '';
    },
    getAfterPromoHtml(item, html) {
      return '';
    }
  };
});

